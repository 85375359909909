import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import axios from './axios-api';

import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from './hoc/Aux1/Aux1';
import * as actions from "./store/actions/index";
import CacheBuster from './CacheBuster';
//import OnlineStatus from "./components/OnlineStatus/OnlineStatus";

const Auth = lazy(() => import("./containers/Auth/Auth"));
const TravelHistory = lazy(() => import('./components/TravelHistory/TravelHistory'));
const Feedback = lazy(() => import('./components/Feedback/Feedback'));
const Tools = lazy(() => import('./components/Tools/Tools'));
const DashboardMgr = lazy(() => import('./components/DashboardMgr/DashboardMgr'));
const Launch = lazy(() => import('./components/Launch/Launch'));
const DashboardDrillDown = lazy(() => import('./components/DashboardDrillDown/DashboardDrillDown'));
const OnlineStatus = lazy(() => import('./components/OnlineStatus/OnlineStatus'));
const Datagrid = lazy(() => import('./components/Datagrid/Datagrid'));
const PublicWebsite = lazy(() => import('./components/PublicWebsite/PublicWebsite'));
const TicketSupport = lazy(() => import('./components/TicketSupport/TicketSupport'));
const Tutorial = lazy(() => import('./components/Tutorial/Tutorial'));
//const SSO = lazy(() => import("./containers/Auth/SSO"));

const windowUrl = window.location.hash;
if(windowUrl.includes("token"))
{
    const url = windowUrl.replace("#/?id","")
    const id = url.substring(
      url.indexOf("=") + 1, 
      url.lastIndexOf("&")
  );
    let sp = url.replace(id,"");
    sp = sp.replace("=&token=","");

    localStorage.setItem('tempuser', id);
    localStorage.setItem('temptoken', sp);
    localStorage.setItem('templink', '/Ticket');
}

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    //debugger;
    
  }

  render() {
    // if(localStorage.temptoken !== '')
    // {
    //  return <Redirect to="/auth" />
    // }

    let routes = (
      <Suspense
        fallback={
          <div className="loader h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Auth} />
          {/* <Route path="/sso" component={SSO} /> */}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Suspense
          fallback={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Launch} />
            <Route path="/logout" component={Logout} />
            <Route path="/TravelHistory" component={TravelHistory} />
            <Route path="/Feedback" component={Feedback} />
            <Route path="/Tools" component={Tools} />
            <Route path="/Dashboard" component={DashboardMgr} />
            <Route exact path="/Launch" component={Launch} />
            <Route exact path="/DashboardDrillDown" component={DashboardDrillDown} />
            <Route exact path="/OnlineStatus" component={OnlineStatus} />
            <Route exact path="/Datagrid" component={Datagrid} />
            <Route exact path="/PublicWebsite" component={PublicWebsite} />
            <Route exact path="/Ticket" component={TicketSupport} />
            <Route exact path="/Tutorial" component={Tutorial} />
            <Route path="**" render={
                () => (
                    <article>
                        <h1 className="text-danger">No Route Configured!</h1>
                        <h4 className="text-danger">Please check your Route Configuration</h4>
                    </article>
                )
            } />
          </Switch>
        </Suspense>
      );
    }

    return (
      
      <Aux1>
        <CacheBuster>
        {() => {

              fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
              .then((response) => response.json())
              .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                global.metaVersion = latestVersion;
               
                if(latestVersion !== currentVersion)
                {
                  const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

                  if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    console.log('Clearing cache and hard reloading...')
                    
                  if (window.caches !== undefined) {
                
                    caches.keys().then(async function(names) {
                      await Promise.all(names.map(name => caches.delete(name)));
                      });
                  }

                  // delete browser cache and hard reload
                  localStorage.removeItem('token');
                  localStorage.removeItem('expirationDate');
                  localStorage.removeItem('userId');
                  localStorage.removeItem('role');
                  localStorage.removeItem('role2');
                  localStorage.removeItem('username');
                  localStorage.removeItem('khidmatguzar');
                  localStorage.removeItem('link');
                  localStorage.removeItem('photo');
                  localStorage.removeItem('temptoken');
                  localStorage.removeItem('tempuser');
                  window.location.reload(true);
                    
                  } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    
                  }
              }
              });
          
          return (
           
              <Layout>{routes}</Layout>
            
          );
        }}
      </CacheBuster>
        
      </Aux1>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
