import axios from 'axios';

const instance = axios.create({
    
    //baseURL:'https://localhost:44338/'
    baseURL:'/API/'
    
    // baseURL:'https://oms.mazaraat.org/API/'
    
});

export default instance;